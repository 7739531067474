<template lang="pug">
div
  .container-fluid
    .row
      .col
        h2.text-center {{plateName}}
    .row
      .col-6.col-sm-3
        .form-group
          label(for="entrance") Тип об'єкту
          select#entrance.custom-select(v-model='store.entrObjType')
            option(v-for="mainType in mainTypes" v-bind:value="{ id: mainType.id, text: mainType.name, textTranslate: mainType.translate }") {{ mainType.name }}
      .col-6.col-sm-3
        .form-group
          label Напрямок А
          .input-group
              input#apartments-min.form-control(type="text" autocomplete="off" aria-label="partA1" maxlength="4" v-model  = "store.partAStartInput")
              input#apartments-max.form-control(type="text" autocomplete="off" aria-label="partA2" maxlength="4" v-model = "store.partAEndInput")
      .col-6.col-sm-3
         .form-group
          label Напрямки

          .btn-group.directions(role="group")
            button.btn.btn-primary(type="button", :class="{ active: store.dirEntrLeft }", @click="directions(), store.dirEntrLeft = true")
              svg(width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg")
                path(d="M12.75 23.75C12.75 20.9497 12.75 19.5496 13.295 18.48C13.7743 17.5392 14.5392 16.7743 15.48 16.295C16.5496 15.75 17.9497 15.75 20.75 15.75H24.75" stroke="currentColor" stroke-width="2")
                path(d="M9.25 21.2148L12.7855 24.7504L16.3211 21.2148" stroke="currentColor" stroke-width="2")
                path(d="M12.75 6.75V7.75C12.75 10.5503 12.75 11.9504 13.295 13.02C13.7743 13.9608 14.5392 14.7257 15.48 15.205C16.5496 15.75 17.9497 15.75 20.75 15.75H24.75" stroke="currentColor" stroke-width="2")
                path(d="M9.25 9.28521L12.7855 5.74963L16.3211 9.28521" stroke="currentColor" stroke-width="2")

            button.btn.btn-primary(type="button", :class="{ active: store.dirEntrForward }", @click="directions(), store.dirEntrForward = true")
              svg(width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg")
                path( d="M8 9.5C9.8613 9.5 11.7919 9.5 12.5451 9.7447C14.0673 10.2393 15.2607 11.4327 15.7553 12.9549C16 13.7081 16 14.6387 16 16.5V25.5" stroke="currentColor" stroke-width="2")
                path(d="M10.5352 6L6.99963 9.5355L10.5352 13.0711" stroke="currentColor" stroke-width="2")
                path(d="M24 9.5C22.1387 9.5 20.2081 9.5 19.4549 9.7447C17.9327 10.2393 16.7393 11.4327 16.2447 12.9549C16 13.7081 16 14.6387 16 16.5V25.5" stroke="currentColor" stroke-width="2")
                path(d="M21.4648 6L25.0004 9.5355L21.4648 13.0711" stroke="currentColor" stroke-width="2")
            
            button.btn.btn-primary(type="button", :class="{ active: store.dirEntrRight }", @click="directions(), store.dirEntrRight = true")
              svg(width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg")
                path(d="M19.25 6.75012C19.25 9.5504 19.25 10.9505 18.705 12.0201C18.2257 12.9609 17.4608 13.7258 16.52 14.2052C15.4504 14.7501 14.0503 14.7501 11.25 14.7501H7.25" stroke="currentColor" stroke-width="2")
                path(d="M22.7501 9.28532L19.2146 5.74976L15.679 9.28532" stroke="currentColor" stroke-width="2")
                path(d="M19.25 23.75V22.75C19.25 19.9497 19.25 18.5496 18.705 17.48C18.2257 16.5392 17.4608 15.7743 16.52 15.295C15.4504 14.75 14.0503 14.75 11.25 14.75H7.25" stroke="currentColor" stroke-width="2")
                path(d="M22.7501 21.2148L19.2146 24.7504L15.679 21.2148" stroke="currentColor" stroke-width="2")
              
      .col-6.col-sm-3
        .form-group
          label Напрямок Б
          .input-group
              input#apartments-min.form-control(type="text" autocomplete="off" aria-label="partA1" maxlength="4" v-model  = "store.partBStartInput")
              input#apartments-max.form-control(type="text" autocomplete="off" aria-label="partA2" maxlength="4" v-model = "store.partBEndInput")
      .d-xs-none.col-sm
    
  BulkDownloadList()

  .container-fluid
    .row
      .col.preview
        // SVG defs
        svg(
          width="0",
          height="0",
          viewBox="0,0,0,0",
          viewport xmlns="http://www.w3.org/2000/svg"
        )
          SvgDefs(            
              :plateWidth = "this.plateWidth"            
              :clipWidth = '1'
              )

        // SVG Placeholder
        #containerPlaceholder(
          v-if="isPlaceholder"
          )

          svg#svgPlaceholder(:width="sizeM(480)", :height="sizeM(280)",:viewBox="sizeM(-20) +' '+ sizeM(-20) +' '+ sizeM(520) +' '+ sizeM(320)", :class="{ material: isMaterial}", @click="isMaterial = !isMaterial", viewport xmlns="http://www.w3.org/2000/svg")

            rect.plate(:width="sizeM(480)",:height="sizeM(280)" x="0" y="0" rx="16" ry="16" fill="#fff" stroke="#000" stroke-width=".25")          
            
            g.arrows(v-if="store.dirEntrLeft")
              text.prefix(:x="sizeM(186)",:y="sizeM(54)",:font-size="sizeM(25)" text-anchor="start" style="font-weight: 400; font-family: DniproCity-SemiBold; letter-spacing: .05em; fill: #000;") {{store.entrObjType.text}}

              text.entranceA(:x="sizeM(186)",:y="sizeM(124)",:font-size="sizeM(62)" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Bold; letter-spacing: .018em; fill: #000;") {{PartAStartPlace}} — {{PartAEndPlace}}
              path.arrows(:transform="'translate('+ sizeM(47) +',' + sizeM(74) +'), scale(' + sizeM(1) +','+ sizeM(1) +')'" d="M34.89 59.87a17.58 17.58 0 0 1-17.56-17.56V6.5L26 15.16l2.41-2.4L15.63 0 2.87 12.76l2.4 2.4 8.66-8.66v37.77H14a21 21 0 0 0 12.61 17.3A21 21 0 0 0 14 78.87h-.1v36.63l-8.66-8.66-2.4 2.4L15.63 122l12.76-12.76-2.39-2.4-8.65 8.66V80.84a17.58 17.58 0 0 1 17.54-17.57H84v-3.4Z" fill="#0075c9")
              text.entranceB(:x="sizeM(186)",:y="sizeM(194)",:font-size="sizeM(62)" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Bold; letter-spacing: .018em; fill: #000;") {{PartBStartPlace}} — {{PartBEndPlace}}

              text.translit(:x="sizeM(186)",:y="sizeM(245)",:font-size="sizeM(25)" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .025em; fill: #000;") {{store.entrObjType.textTranslate}}


            g.arrows(v-if="store.dirEntrForward")
              text.prefix(:x="sizeM(54)",:y="sizeM(54)",:font-size="sizeM(25)" text-anchor="start" style="font-weight: 400; font-family: DniproCity-SemiBold; letter-spacing: .05em; fill: #000;") {{store.entrObjType.text}}

              text.entranceA(:x="sizeM(98)",:y="sizeM(124)",:font-size="sizeM(62)" text-anchor="middle" style="font-weight: 400; font-family: DniproCity-Bold; letter-spacing: .018em; fill: #000;") {{PartAStartPlace}}
              text.entranceA(:x="sizeM(98)",:y="sizeM(154)",:font-size="sizeM(62)" text-anchor="middle" style="font-weight: 400; font-family: DniproCity; letter-spacing: .018em; fill: #000;") —
              text.entranceA(:x="sizeM(98)",:y="sizeM(194)",:font-size="sizeM(62)" text-anchor="middle" style="font-weight: 400; font-family: DniproCity-Bold; letter-spacing: .018em; fill: #000;") {{PartAEndPlace}}
              
              path.arrows(:transform="'translate('+ sizeM(176) +',' + sizeM(124) +'), scale(' + sizeM(1) +','+ sizeM(1) +')'" d="m112.26 0-2.4 2.4 8.64 8.65H79.77v.09A21 21 0 0 0 62.5 23.73a21 21 0 0 0-17.27-12.59v-.09H6.5l8.64-8.65-2.4-2.4L0 12.74l12.74 12.74 2.4-2.4-8.64-8.64h36.76A17.56 17.56 0 0 1 60.8 32v39.76h3.4V32a17.56 17.56 0 0 1 17.54-17.56h36.76l-8.64 8.64 2.4 2.4L125 12.74Z" fill="#0075c9")

              text.entranceB(:x="sizeM(375)",:y="sizeM(124)",:font-size="sizeM(62)" text-anchor="middle" style="font-weight: 400; font-family: DniproCity-Bold; letter-spacing: .018em; fill: #000;") {{PartBStartPlace}}
              text.entranceB(:x="sizeM(375)",:y="sizeM(154)",:font-size="sizeM(62)" text-anchor="middle" style="font-weight: 400; font-family: DniproCity; letter-spacing: .018em; fill: #000;") —
              text.entranceB(:x="sizeM(375)",:y="sizeM(194)",:font-size="sizeM(62)" text-anchor="middle" style="font-weight: 400; font-family: DniproCity-Bold; letter-spacing: .018em; fill: #000;") {{PartBEndPlace}}

              text.translit(:x="sizeM(54)",:y="sizeM(245)",:font-size="sizeM(25)" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .025em; fill: #000;") {{store.entrObjType.textTranslate}}

            g.arrows(v-if="store.dirEntrRight")
              text.prefix(:x="sizeM(54)",:y="sizeM(54)",:font-size="sizeM(25)" text-anchor="start" style="font-weight: 400; font-family: DniproCity-SemiBold; letter-spacing: .05em; fill: #000;") {{store.entrObjType.text}}

              text.entranceA(:x="sizeM(54)",:y="sizeM(124)",:font-size="sizeM(62)" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Bold; letter-spacing: .018em; fill: #000;") {{PartAStartPlace}} — {{PartAEndPlace}}
              path(:transform="'translate('+ sizeM(346) +',' + sizeM(74) +'), scale(' + sizeM(1) +','+ sizeM(1) +')'" d="M71 115.5V78.87h-.1a21 21 0 0 0-12.61-17.3 21 21 0 0 0 12.61-17.3h.1V6.5l8.65 8.66 2.41-2.4L69.3 0 56.54 12.76l2.4 2.4L67.6 6.5v35.81A17.59 17.59 0 0 1 50 59.87H0v3.4h50a17.59 17.59 0 0 1 17.6 17.57v34.66l-8.66-8.66-2.4 2.4L69.3 122l12.76-12.76-2.41-2.4Z" fill="#0075c9")
              text.entranceB(:x="sizeM(54)",:y="sizeM(194)",:font-size="sizeM(62)" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Bold; letter-spacing: .018em; fill: #000;") {{PartBStartPlace}} — {{PartBEndPlace}}

              text.translit(:x="sizeM(54)",:y="sizeM(245)",:font-size="sizeM(25)" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .025em; fill: #000;") {{store.entrObjType.textTranslate}}
            
          
            g#svgMaterial(style="display: none;",:transform="'scale('+ sizeM(1) +')'")
              rect.reflex(width="480" height="280" rx="16" ry="16" fill="none")

            g#fix(v-if ="store.fixersDisplay") 
              circle(cx="16" cy="16" r="3" fill="#ccc")
              circle(:cx="sizeM(this.plateWidth)-16",cy="16" r="3" fill="#ccc")
              circle(:cx="sizeM(this.plateWidth)-16",:cy="sizeM(280)-16",r="3" fill="#ccc")
              circle(cx="16",:cy="sizeM(280)-16",r="3" fill="#ccc")
         

        // SVG Template
        #containerTemplate(
          style="position: relative;"
          v-else)

          .cartPreview(
            :class="{ collection: isCollection}",
            ref="refCart",
            :style="'top:'+ this.rectY +'px; right: calc(100vw - '+ this.rectR +'px); width:'+ this.rectW +'px; height:'+ this.rectH +'px;'", v-html="animationBuff"         
            )

          svg#svgTemplate(
            :width="sizeM(480)", 
            :height="sizeM(280)",
            :viewBox="sizeM(-20) +' '+ sizeM(-20) +' '+ sizeM(520) +' '+ sizeM(320)", 
            :class="{ material: isMaterial}", 
            @click="isMaterial = !isMaterial", 
            viewport xmlns="http://www.w3.org/2000/svg",
            preserveAspectRatio="xMinYMin meet"
            ) 

            rect.plate(:width="sizeM(480)",:height="sizeM(280)" x="0" y="0" rx="16" ry="16" fill="#fff" stroke="#000" stroke-width=".25", ref="refReady")          
            
            g.arrows(v-if="store.dirEntrLeft")
              text.prefix(:x="sizeM(186)",:y="sizeM(54)",:font-size="sizeM(25)" text-anchor="start" style="font-weight: 400; font-family: DniproCity-SemiBold; letter-spacing: .05em; fill: #000;") {{store.entrObjType.text}}

              text.entranceA(:x="sizeM(186)",:y="sizeM(124)",:font-size="sizeM(62.0889)" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Bold; letter-spacing: .018em; fill: #000;") {{store.partAStart}}
                tspan() &thinsp;–&thinsp;
                tspan() {{store.partAEnd}}
              path.arrows(:transform="'translate('+ sizeM(47) +',' + sizeM(74) +'), scale(' + sizeM(1) +','+ sizeM(1) +')'" d="M34.89 59.87a17.58 17.58 0 0 1-17.56-17.56V6.5L26 15.16l2.41-2.4L15.63 0 2.87 12.76l2.4 2.4 8.66-8.66v37.77H14a21 21 0 0 0 12.61 17.3A21 21 0 0 0 14 78.87h-.1v36.63l-8.66-8.66-2.4 2.4L15.63 122l12.76-12.76-2.39-2.4-8.65 8.66V80.84a17.58 17.58 0 0 1 17.54-17.57H84v-3.4Z" fill="#0075c9")
              text.entranceB(:x="sizeM(186)",:y="sizeM(194)",:font-size="sizeM(62.0889)" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Bold; letter-spacing: .025em; fill: #000;") {{store.partBStart}}
                tspan() &thinsp;–&thinsp;
                tspan() {{store.partBEnd}}

              text.translit(:x="sizeM(186)",:y="sizeM(245)",:font-size="sizeM(25)" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .025em; fill: #000;") {{store.entrObjType.textTranslate}}


            g.arrows(v-if="store.dirEntrForward")
              text.prefix(:x="sizeM(54)",:y="sizeM(54)",:font-size="sizeM(25)" text-anchor="start" style="font-weight: 400; font-family: DniproCity-SemiBold; letter-spacing: .05em; fill: #000;") {{store.entrObjType.text}}

              text.entranceA(:x="sizeM(98)",:y="sizeM(124)",:font-size="sizeM(62)" text-anchor="middle" style="font-weight: 400; font-family: DniproCity-Bold; letter-spacing: .018em; fill: #000;") {{store.partAStart}}
              text.entranceA(:x="sizeM(98)",:y="sizeM(154)",:font-size="sizeM(62)" text-anchor="middle" style="font-weight: 400; font-family: DniproCity; letter-spacing: .018em; fill: #000;") –
              text.entranceA(:x="sizeM(98)",:y="sizeM(194)",:font-size="sizeM(62)" text-anchor="middle" style="font-weight: 400; font-family: DniproCity-Bold; letter-spacing: .018em; fill: #000;") {{store.partAEnd}}
              
              path.arrows(:transform="'translate('+ sizeM(176) +',' + sizeM(124) +'), scale(' + sizeM(1) +','+ sizeM(1) +')'" d="m112.26 0-2.4 2.4 8.64 8.65H79.77v.09A21 21 0 0 0 62.5 23.73a21 21 0 0 0-17.27-12.59v-.09H6.5l8.64-8.65-2.4-2.4L0 12.74l12.74 12.74 2.4-2.4-8.64-8.64h36.76A17.56 17.56 0 0 1 60.8 32v39.76h3.4V32a17.56 17.56 0 0 1 17.54-17.56h36.76l-8.64 8.64 2.4 2.4L125 12.74Z" fill="#0075c9")

              text.entranceB(:x="sizeM(375)",:y="sizeM(124)",:font-size="sizeM(62)" text-anchor="middle" style="font-weight: 400; font-family: DniproCity-Bold; letter-spacing: .018em; fill: #000;") {{store.partBStart}}
              text.entranceB(:x="sizeM(375)",:y="sizeM(154)",:font-size="sizeM(62)" text-anchor="middle" style="font-weight: 400; font-family: DniproCity; letter-spacing: .018em; fill: #000;") –
              text.entranceB(:x="sizeM(375)",:y="sizeM(194)",:font-size="sizeM(62)" text-anchor="middle" style="font-weight: 400; font-family: DniproCity-Bold; letter-spacing: .018em; fill: #000;") {{store.partBEnd}}

              text.translit(:x="sizeM(54)",:y="sizeM(245)",:font-size="sizeM(25)" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .025em; fill: #000;") {{store.entrObjType.textTranslate}}

            g.arrows(v-if="store.dirEntrRight")
              text.prefix(:x="sizeM(54)",:y="sizeM(54)",:font-size="sizeM(25)" text-anchor="start" style="font-weight: 400; font-family: DniproCity-SemiBold; letter-spacing: .05em; fill: #000;") {{store.entrObjType.text}}

              text.entranceA(:x="sizeM(54)",:y="sizeM(124)",:font-size="sizeM(62)" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Bold; letter-spacing: .018em; fill: #000;") {{store.partAStart}}
                tspan() &thinsp;–&thinsp;
                tspan() {{store.partAEnd}}
              path(:transform="'translate('+ sizeM(346) +',' + sizeM(74) +'), scale(' + sizeM(1) +','+ sizeM(1) +')'" d="M71 115.5V78.87h-.1a21 21 0 0 0-12.61-17.3 21 21 0 0 0 12.61-17.3h.1V6.5l8.65 8.66 2.41-2.4L69.3 0 56.54 12.76l2.4 2.4L67.6 6.5v35.81A17.59 17.59 0 0 1 50 59.87H0v3.4h50a17.59 17.59 0 0 1 17.6 17.57v34.66l-8.66-8.66-2.4 2.4L69.3 122l12.76-12.76-2.41-2.4Z" fill="#0075c9")
              text.entranceB(:x="sizeM(54)",:y="sizeM(194)",:font-size="sizeM(62)" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Bold; letter-spacing: .018em; fill: #000;") {{store.partBStart}}
                tspan() &thinsp;–&thinsp;
                tspan() {{store.partBEnd}}

              text.translit(:x="sizeM(54)",:y="sizeM(245)",:font-size="sizeM(25)" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .025em; fill: #000;") {{store.entrObjType.textTranslate}}
            
          
            g#svgMaterial(style="display: none;",:transform="'scale('+ sizeM(1) +')'")
              rect.reflex(width="480" height="280" rx="16" ry="16" fill="none")

            g#fix(v-if ="store.fixersDisplay") 
              circle(cx="16" cy="16" r="3" fill="#ccc")
              circle(:cx="sizeM(this.plateWidth)-16",cy="16" r="3" fill="#ccc")
              circle(:cx="sizeM(this.plateWidth)-16",:cy="sizeM(280)-16",r="3" fill="#ccc")
              circle(cx="16",:cy="sizeM(280)-16",r="3" fill="#ccc")

            SvgRequirements(            
              :plateWidth = "this.plateWidth"
              :plateHeight = "this.plateHeight"
              :plateName = "this.plateName"
              :isWhiteBack = "true"
              :immutableSize = "false"
              )

        .sign-size.text-center 
          span розмір покажчика
          | {{sizeM(480)}}x{{sizeM(280)}} мм

    .row
      .col.svgContainer

  .container-fluid
    .row.row-cols-1.plate-options
      .col-xs-12.col-sm-8.offset-sm-2.col-md-6.offset-md-3
        .form-group
          .custom-control.custom-switch
            input.custom-control-input(type="checkbox" id="largerSize" v-model="store.sizeMultiplier")
            label.custom-control-label(for="largerSize") збільшений розмір покажчика
              br
              small.caption Якщо відстань від фасаду до краю хідника більше ніж&nbsp;15&nbsp;метрів      
      .col-xs-12.col-sm-8.offset-sm-2.col-md-6.offset-md-3
        .form-group
          .custom-control.custom-switch
            input#fixMarks.custom-control-input(type="checkbox" v-model="store.fixersDisplay")
            label.custom-control-label(for="fixMarks") показати кріплення
    
    .row.plate-download
      .col.text-center
        button#btn-download.btn.btn-primary.btn-lg(type="button", :disabled="generateNotReady", @click="convertFunc(false)") Завантажити                
        button#add-cart.btn.btn-primary.btn-lg(type="button", :disabled="generateNotReady", @click="convertFunc(true)") Додати до набору
    .row.plate-download
      .col-xs-12.col-sm-8.offset-sm-2.col-md-6.offset-md-3.text-center        
        p.caption.text-center Макет у форматі .pdf з літерами у кривих
          br
          br
          | Під час завантаження матеріалів ви погоджуєтесь з ліцензією 
          a(href="https://creativecommons.org/licenses/by-nd/4.0/deed.uk" target="_blank") CC BY-ND 4.0 Із 
          | Зазначенням Авторства — Без Похідних 4.0 Міжнародна
          
    .svgInstance 
      svg#svgInstance(:width="sizeM(sizeXR(0))", :height="sizeM(280)",:viewBox="sizeM(0) +' '+ sizeM(0) +' '+ sizeM(480) +' '+ sizeM(500)", preserveAspectRatio="xMidYMin meet" viewport xmlns="http://www.w3.org/2000/svg")
        //- rect(width="100" height="100" fill="red")

</template>

<script>
import { store } from '@/store.js';

import * as pdfFuncs from '@/svgToPdfConvertJS.js';

import SvgRequirements from '@/components/SvgRequirements.vue'
import SvgDefs from '@/components/SvgDefs.vue'
import BulkDownloadList from '@/components/BulkDownloadList.vue'

export default {
  name: 'Number',
  components: {
    SvgRequirements,
    SvgDefs,
    BulkDownloadList,
  },

  data(){
    return{
      store,
      
      PartAStartPlace: '1',
      PartAEndPlace: '36',
      PartBStartPlace: '36',
      PartBEndPlace: '72',

      mainTypes: [
      {id: 1, name: 'квартири', translate: 'apartments'},
      {id: 2, name: 'корпус', translate: 'entrance'},
      {id: 3, name: 'під’їзд', translate: 'entrance'},
      {id: 4, name: 'блок', translate: 'entrance'},
      {id: 5, name: 'вхід', translate: 'entrance'},
      {id: 6, name: 'секція', translate: 'section'},
      ],
      
      isMaterial: false,
      isPlaceholder: true,
      generateNotReady: true,
      isCollection: false,

      plateWidth:480,
      plateHeight:280,
      plateName: 'Напрямок до входу',

      rectR: 0,
      rectY: 0,
      rectW: 0,
      rectH: 0,

      animationBuff: 0,
    }
  },

  watch:
  {
    'store.partAStartInput': function ()
    {
      this.store.partAStart = this.numProcessing(store.partAStartInput);
      this.minReq();
    },

    'store.partAEndInput': function ()
    {
      this.store.partAEnd = this.numProcessing(store.partAEndInput);
      this.minReq();
    },

    'store.partBStartInput': function ()
    {
      this.store.partBStart = this.numProcessing(store.partBStartInput);
      this.minReq();
    },

    'store.partBEndInput': function ()
    {
      this.store.partBEnd = this.numProcessing(store.partBEndInput);
      this.minReq();
    }, 

    'store.bulkData.length': function()
    {
      if(this.store.bulkData.length > 0)
      {
        this.store.isCartEmpty = false;
      }
      else
      {
        this.store.isCartEmpty = true;
      }
    },
  },

  methods:
  {     
    directions() 
    {
      this.store.dirEntrLeft = false;
      this.store.dirEntrForward = false;
      this.store.dirEntrRight = false;
    },
    
    convertFunc(isBulk)
    {
      let dirEntrArrow = '';
      if(this.store.dirEntrLeft)
      {
        dirEntrArrow = '_left';
      }
      else if(this.store.dirEntrForward)
      {
        dirEntrArrow = '_forward';
      }
      else
      {
        dirEntrArrow = '_right';
      }

      let pdfMainName = this.store.partAStart + '-' + this.store.partAEnd + '_' + this.store.partBStart + '-' + this.store.partBEnd + '_id' + this.store.entrObjType.id + dirEntrArrow;
      let pdfNameOptions = '_direction-entr-sign';
      if(this.store.sizeMultiplier)
      {
        pdfNameOptions += '_XL';
      }
      if(this.store.fixersDisplay)
      {
        pdfNameOptions += '_FIX';
      }
      pdfNameOptions += '.pdf';

      pdfFuncs.svgToPdfCovert(10, 10, this.sizeM(480), this.sizeM(500), this.sizeM(480)+20, this.sizeM(450)+30, 
      pdfMainName, pdfNameOptions, this.sizeM(30), this.sizeM(312), this.sizeM(this.plateHeight), 'svgInstance', 'svgTemplate', isBulk, this.store.bulkData);

      if(isBulk)
      {
        this.getPosition();

        this.animationBuff = document.querySelector('#svgTemplate').outerHTML;

        let isCopy = false;

        if(typeof this.store.bulkData.length !== 'undefined')
        {
            for(let i = 0; i < this.store.bulkData.length; i++)
            {
                if(this.store.bulkData[i].pdfName == (pdfMainName + pdfNameOptions))
                {
                    isCopy = true;
                }  
            }
        }

        if(!isCopy)
        {
          setTimeout(() => {
            this.isCollection = true;
          }, 100); 
          setTimeout(() => {
            this.animationBuff = 0;
            this.isCollection = !this.isCollection;        
          }, 1000); 
        }
      }
    },

    getPosition() {      
      this.rectY = parseInt(this.$refs.refReady.getBoundingClientRect().y);
      this.rectW = parseInt(this.$refs.refReady.getBoundingClientRect().width);
      this.rectH = parseInt(this.$refs.refReady.getBoundingClientRect().height);
      this.rectR = parseInt(this.$refs.refReady.getBoundingClientRect().right);        
    },

    sizeM(size)
    {
      if(this.store.sizeMultiplier)
      {
        return size;
      }
      else
      {
        return size * 0.75;
      }
    },

    sizeXR(offset)
    {
      return this.plateWidth - offset;
    },

    numProcessing(inputNum)
    {
      var outputNum = inputNum;

      if(inputNum != '')
      {
        outputNum = outputNum.replace(/[^0-9]/gi, '');
        var lastSign = inputNum[inputNum.length - 1].replace(/[^0-9]/gi, '');
        if(lastSign == '')
        {
          outputNum = outputNum + inputNum[inputNum.length - 1].replace(/[a-z!? &{},.\\/:;"'|[\]@%$*\-+=~`><()#№]/gi, '');
        }
      }
      else
      {
        outputNum = '';
      }

      return outputNum.toUpperCase();
    },

    minReq()
    {
      if(this.store.partAStart != '' || this.store.partAEnd != '' || this.store.partBStart != '' || this.store.partBEnd != '')
      {
        this.isPlaceholder = false;
        this.isMaterial = true;        
      }      
      else
      {
        this.isPlaceholder = true;
        this.isMaterial = false;
      }

      if(this.store.partAStart == '' || this.store.partAEnd == '' || this.store.partBStart == '' || this.store.partBEnd == '')
      {
        this.generateNotReady = true;
      }
      else 
      {
        if((parseInt(this.store.partAStart.replace(/[^0-9]/gi, '')) < parseInt(this.store.partAEnd.replace(/[^0-9]/gi, ''))) && (parseInt(this.store.partBStart.replace(/[^0-9]/gi, '')) < parseInt(this.store.partBEnd.replace(/[^0-9]/gi, ''))))
        {
          this.generateNotReady = false;
        }
        else
        {
          this.generateNotReady = true;
        }
      }
    }, 
  },

  mounted() 
  {
    this.minReq();
  },
}

</script>

<style lang="scss" scoped>
@import '@/assets/styles/_svg-templates.scss';

.container-fluid {
  max-width: 600px;  
}

.preview {
  min-height: 350px;
    @include respond-below(md) {
      min-height: 240px;
      svg {
        width: 100%;
        padding: 0 20px;
        height: auto;
      }
    }
}

.directions {
  .btn {
    padding: 1px 4px;
    svg {
      width: 32px;
      height: 32px;
    }
  }
}

.cartPreview {  
  display: inline-block; 
  width: 300px;
  height: 160px;

  position: fixed;
  top: 200px;
  right: calc(50% - 300px);
  
  visibility: hidden;
  opacity: 1;
  background-color: transparent;
  border-radius: 8px;
  box-shadow: 0 4px 80px 0 rgba(0,0,0,.3);  
  border: none;

  &.collection {    
    visibility: visible;
    top: 50px !important;
    right: 50px !important;
    // background-color: rgba(255,255,255,1);
    
    opacity: 0;
    // z-index: 10000;
    box-shadow: 0 4px 20px 0 rgba(0,0,0,.3);

    transform: scale(.1);
    transform-origin: top right;

    transition: top .5s cubic-bezier(0, 0, 0.3, 1.8), right .5s ease-out, transform .5s ease-out, opacity .75s ease-in, box-shadow .5s ease-out;
  }
}

</style>